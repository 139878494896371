<template>
  <div class="page-wrapper shifts__wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div class="page-title-inner">
            <div class="page-title">Services</div>
            <button class="button button--fill" @click="showAddService">
              <i class="ri-add-circle-fill"></i>
              <span> Add New Service</span>
            </button>
            <button
              class="icon-button icon-button--round icon-button--mobile icon-button--blue button-fly"
              @click="showAddService"
            >
              <i class="ri-add-circle-fill"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="table">
        <div class="table__title-wrapper">
          <div class="container">
            <div class="table__title-inner">
              <div class="table__title-item">On/Off</div>
              <div class="table__title-item">Name</div>
              <div class="table__title-item">Price</div>
              <div class="table__title-item">Fuel Surcharge</div>
              <div class="table__title-item">Shifts</div>
              <div class="table__title-item">Relation</div>
            </div>
          </div>
        </div>
        <div class="table__main">
          <Service v-for="service in services" :key="service.id" :info="service" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Service from './Service'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Services',
  components: {Service, InfiniteLoading},
  data: () => ({
    isLoading: false
  }),
  computed: {
    services() {
      return this.$store.getters['services/services']
    },
    hasMoreResults() {
      return this.services.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['services/pagination'].total
    },
    page() {
      return this.$store.getters['services/page']
    }
  },
  async created() {
    if (!this.$store.getters['services/pagination'].total) {
      await this.$store.dispatch('services/fetch')
    }
  },
  methods: {
    showAddService() {
      this.$root.$emit('ShowSidebar', 'AddService')
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.$store.dispatch('services/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
