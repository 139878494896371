var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "table__row",
      class: { "table__row--dec": !_vm.info.isActive }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "table__row-inner" }, [
          _c(
            "div",
            { staticClass: "table__cell" },
            [
              _c(
                "el-popover",
                {
                  attrs: { placement: "bottom-start" },
                  model: {
                    value: _vm.visible,
                    callback: function($$v) {
                      _vm.visible = $$v
                    },
                    expression: "visible"
                  }
                },
                [
                  !!_vm.info.isActive
                    ? _c("span", [_vm._v("Deactivate ")])
                    : _c("span", [_vm._v("Activate ")]),
                  _c("span", [_vm._v(_vm._s(_vm.info.name) + "?")]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "margin-top": "12px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "plain" },
                          on: {
                            click: function($event) {
                              _vm.visible = false
                            }
                          }
                        },
                        [_vm._v("cancel")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.changeStatus }
                        },
                        [_vm._v("confirm")]
                      )
                    ],
                    1
                  ),
                  _c("el-switch", {
                    attrs: { slot: "reference", value: _vm.info.isActive },
                    slot: "reference"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "table__cell" }, [
            _c("span", {
              staticClass: "table__cell--round",
              style: { backgroundColor: _vm.info.color }
            }),
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v(_vm._s(_vm.info.name))
            ])
          ]),
          _c("div", { staticClass: "table__cell" }, [
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v("$ " + _vm._s(_vm.info.price))
            ])
          ]),
          _c("div", { staticClass: "table__cell" }, [
            _c("div", { staticClass: "indicator__wrapper" }, [
              _vm.info.fuelSuperCharge
                ? _c("span", { staticClass: "indicator indicator--green" }, [
                    _vm._v("yes")
                  ])
                : _c("span", { staticClass: "indicator indicator--orange" }, [
                    _vm._v("no")
                  ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "table__cell table__cell--horizontal" },
            _vm._l(_vm.info.shifts, function(shift) {
              return _c(
                "span",
                { key: shift.id, staticClass: "table__cell-title" },
                [
                  _c("span", {
                    staticClass: "table__cell--round",
                    style: { backgroundColor: shift.color }
                  }),
                  _vm._v(" " + _vm._s(shift.name) + " ")
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "table__cell table__cell--relation" }, [
            _vm.info.isPublic
              ? _c("span", [_vm._v("Public Clients")])
              : _vm.info.customers.length
              ? _c("span", [_vm._v("Enterprise")])
              : _c("span", [_vm._v("General")])
          ]),
          _c(
            "div",
            { staticClass: "table__cell table__cell--end" },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "Edit" }
                        },
                        [
                          _c("i", { staticClass: "ri-pencil-fill" }),
                          _vm._v(" Edit ")
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--remove",
                          attrs: { command: "Remove" }
                        },
                        [
                          _c("i", { staticClass: "ri-delete-bin-7-fill" }),
                          _vm._v(" Remove ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "table__row-card card" }, [
          _c("div", { staticClass: "card__header" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", { staticClass: "card__box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card__indicator",
                      class: {
                        "card__indicator--green": _vm.info.fuelSupercharge
                      }
                    },
                    [_vm._v(" $" + _vm._s(_vm.info.price) + " ")]
                  )
                ]),
                _c("div", { staticClass: "card__box" }, [
                  _c("div", { staticClass: "card__title" }, [
                    _vm._v(_vm._s(_vm.info.name))
                  ])
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "card__box",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c(
                  "el-dropdown",
                  { attrs: { trigger: "click" }, on: { command: _vm.action } },
                  [
                    _c("i", { staticClass: "ri-more-fill" }),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "el-popper-custom el-popper--actions",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "item--edit",
                            attrs: { command: "Edit" }
                          },
                          [
                            _c("i", { staticClass: "ri-pencil-fill" }),
                            _vm._v(" Edit ")
                          ]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "item--remove",
                            attrs: { command: "Remove" }
                          },
                          [
                            _c("i", { staticClass: "ri-delete-bin-7-fill" }),
                            _vm._v(" Remove ")
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "card__body" }, [
            _c(
              "div",
              { staticClass: "card__line" },
              _vm._l(_vm.info.shifts, function(shift) {
                return _c("div", { key: shift.id, staticClass: "button" }, [
                  _c("span", {
                    staticClass: "card__round",
                    style: { backgroundColor: shift.color }
                  }),
                  _vm._v(" " + _vm._s(shift.name) + " ")
                ])
              }),
              0
            ),
            _c("div", { staticClass: "card__line" }, [
              _vm.info.isPublic
                ? _c("span", [_vm._v("Public Clients")])
                : _vm.info.customers.length
                ? _c("span", [_vm._v("Enterprise")])
                : _c("span", [_vm._v("General")])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }